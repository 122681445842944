<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
  <div id="pdf">
    <h2>FeedBack 360 Izvestaj prosecnih ocena za sve zaposlene u kompaniji</h2>

    <table id="table">
      <tr>
        <th>Zaposleni</th>
        <th>Podredjeni</th>
        <th>Nadredjeni</th>
        <th>Isti Nivo</th>
        <th>Ukupno</th>
      </tr>

      <tr v-for="(user,i) in usersSameCompany" :key="i">
        <td>{{user.name}}</td>
        <td>{{user.feedBackTest.prosek.podredjeni.toFixed(2)}}</td>
        <td>{{user.feedBackTest.prosek.nadredjeni.toFixed(2)}}</td>
        <td>{{user.feedBackTest.prosek.istiNivo.toFixed(2)}}</td>
        <td>{{user.feedBackTest.prosek.ukupno.toFixed(2)}}</td>
      </tr>

      <tr>
        <td colspan="4">Prosek Svi</td>
        <td></td>
      </tr>

    </table>

  </div>


</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';

export default {
  props:{
    company:{
      type: Object
    }
  },
computed: mapGetters(['usersSameCompany','user','companys']),
  data(){
    return{
      collaguesNumber:0,
      nadredjeniUkupno:0,
      podredjeniUkupno:0,
      istiUkupno:0,
    }
  },
  methods:{
    ...mapActions(['getCollegues','getProfile','getCompanies']),

  },
  async created(){

    await this.getProfile();
    await this.getCompanies();
    for (var i = 0; i < this.companys.length; i++) {
      if(this.user.type == this.companys[i].name){
        await this.getCollegues(this.companys[i].name);
      }
    }


  },
  beforeCreate: function() {
        document.body.className = 'other';
    }
}
</script>

<style lang="css" scoped>
#pdf{
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: small;
  margin: 20px;
  padding: 15px;
  text-align: center;
}

table{
  border: 2px solid black;
  text-align: center;
  margin:auto;
}
td, th{
  border: 1px solid black;
  text-align: center;
}
tr:nth-child(even){background-color: #aaaacc;}
tr:nth-child(odd){background-color: #ccccff;}
tr:hover {background-color: #afa;}
</style>
